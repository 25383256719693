import React from 'react'

const Footer = () => {
    return (
        <div>
            <div className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-lg-4 col-md-6 footer-about">
                            <div
                                className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-light p-4">
                                <a href="index.html" className="navbar-brand">
                                    {/* <!-- <h1 className="m-0 text-white"><i className="fa fa-user-tie me-2"></i>Startup</h1> --> */}
                                    <img src="img/mine/romanson.png" width="80%" alt="" />
                                </a>
                                {/* <p className="mt-3 mb-4 text-dark">Lorem diam sit erat dolor elitr et, diam lorem justo amet clita
                                    stet eos sit. Elitr dolor duo lorem, elitr clita ipsum sea. Diam amet erat lorem stet eos.
                                    Diam amet et kasd eos duo.</p> */}
                                <form action="">
                                    <div style={{ border: "0.5px solid black" }} className="input-group">
                                        <input type="text" className="form-control border-white p-3" placeholder="Your Email" />
                                        <button className="btn btn-dark">Sign Up</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="row gx-5">
                                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Get In Touch</h3>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-geo-alt text-primary me-2"></i>
                                        <p className="mb-0">S S Kovil Road
                                            PTC Tower
                                            Thampanoor
                                            Thiruvananthapuram
                                            695001</p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-envelope-open text-primary me-2"></i>
                                        <p className="mb-0">romansonpublishinghouse<br/>@gmail.com</p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-telephone text-primary me-2"></i>
                                        <p className="mb-0">+91 91885 22006 <br/> +91 91882 99001 <br/>+91 91882 99002</p><br/>
                                        {/* <p className="mb-0">+91 91882 99002</p><br/>
                                        <p className="mb-0">+91 91882 99001</p> */}
                                    </div>
                                    <div className="d-flex mt-4">
                                        <a className="btn btn-primary btn-square me-2" href="#"><i
                                            className="fab fa-twitter fw-normal"></i></a>
                                        <a className="btn btn-primary btn-square me-2" href="#"><i
                                            className="fab fa-facebook-f fw-normal"></i></a>
                                        <a className="btn btn-primary btn-square me-2" href="#"><i
                                            className="fab fa-whatsapp fw-normal"></i></a>
                                        <a className="btn btn-primary btn-square me-2" href="#"><i
                                            className="fab fa-telegram fw-normal"></i></a>
                                        <a className="btn btn-primary btn-square" href="#"><i
                                            className="fab fa-instagram fw-normal"></i></a>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Quick Links</h3>
                                    </div>
                                    <div className="link-animated d-flex flex-column justify-content-start">
                                        <a className="text-light mb-2" href="/"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>Home</a>
                                        <a className="text-light mb-2" href="about"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>About Us</a>
                                        <a className="text-light mb-2" href="services"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>Our Services</a>
                                        <a className="text-light mb-2" href="testimonial"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>Testimonials</a>
                                        <a className="text-light mb-2" href="blog"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>Latest Blog</a>
                                        <a className="text-light" href="contact"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Popular Links</h3>
                                    </div>
                                    <div className="link-animated d-flex flex-column justify-content-start">
                                        <a className="text-light mb-2" href="/"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>Home</a>
                                        <a className="text-light mb-2" href="about"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>About Us</a>
                                        <a className="text-light mb-2" href="services"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>Our Services</a>
                                        <a className="text-light mb-2" href="testimonial"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>Testimonials</a>
                                        <a className="text-light mb-2" href="blog"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>Latest Blog</a>
                                        <a className="text-light" href="contact"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid text-white" style={{ background: "#061429" }}>
                <div className="container text-center">
                    <div className="row justify-content-end">
                        <div className="col-lg-8 col-md-6">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: "75px" }}>
                                <p className="mb-0">&copy; <a className="text-white border-bottom" href="#">Romanson</a>. All
                                    Rights Reserved,
                                </p>
                                By <a className="border-bottom" href="https://github.com/viwstudios" target="_blank">viwstudios</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer